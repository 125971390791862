.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  max-width: 1280px;
  padding: 1.5rem 1.5rem 0;
}

.introduction {
  margin: 7.5rem 0;
  position: relative;

  &__aside {
    @extend %h1;
    font-size: 2.5rem;
    font-weight: 700;
    left: -5rem;
    position: absolute;
    top: 4rem;
    transform: rotate(-90deg);
  }

  &__title {
    @extend %h1;
    margin: 0 0 0 12rem;
  }
}

.card {
  display: flex;
  flex-wrap: wrap;
  margin-top: 7.5rem;

  &:last-of-type {
    margin-bottom: 5rem;
  }

  &__title {
    font-size: 1.3rem;
    min-width: 8rem;
  }

  &__content {
    flex-grow: 1;
    font-size: 2.1rem;
    max-width: calc(100% - 8rem);
    padding: 4rem;

    &--light {
      background-color: $background-color--light-alt;
      color: $text-color--dark;
    }

    &--dark {
      background-color: $background-color--dark;
      color: $text-color--light;
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.tech-list {
  display: block;
  font-size: 0;
  line-height: 0;
  margin: -0.8rem 0 0;
  padding: 0;

  &__item {
    display: inline-block;
    margin: 0.8rem 0.8rem 0 0;
  }

  &__link {
    border: 1px solid $text-color--light;
    color: $text-color--light;
    display: block;
    font-size: 1.44rem;
    line-height: initial;
    padding: 0.5rem 1rem;
    text-decoration: none;

    &:hover {
      color: $text-color--light;
      transform: translateY(-3px);
    }
  }
}

@media only screen and (max-width: 1280px) {
  .card__title {
    min-width: 100%;
  }

  .card__content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .introduction {
    margin: 5rem 0 0;

    &__title {
      margin-left: 8rem;
    }
  }

  .card {
    margin-top: 5rem;
  }
}
