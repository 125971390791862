html,
body {
  color: $text-color--dark;
  font-family: "Overpass", sans-serif;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1.7;
  text-rendering: optimizeLegibility;
}

h1,
%h1 {
  font-size: 3.2rem;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 1.4em;
}

h2,
%h2 {
  font-size: 2.8rem;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 1.6em;
}

a {
  color: $text-color--dark;

  &:hover {
    color: lighten($text-color--dark, 30%);
  }
}

.text-strike {
  text-decoration: line-through;
}
